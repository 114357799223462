exports.components = {
  "component---src-i-18-n-redirect-jsx": () => import("./../../../src/i18n/redirect.jsx" /* webpackChunkName: "component---src-i-18-n-redirect-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-brand-jsx": () => import("./../../../src/pages/brand.jsx" /* webpackChunkName: "component---src-pages-brand-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-influencer-jsx": () => import("./../../../src/pages/influencer.jsx" /* webpackChunkName: "component---src-pages-influencer-jsx" */),
  "component---src-pages-leader-jsx": () => import("./../../../src/pages/leader.jsx" /* webpackChunkName: "component---src-pages-leader-jsx" */)
}

